import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CommonControlProps } from '@controls/types'
import { SelectedVehicle } from '@forms/SelectedVehicle'
import wizardStyles from '@controls/Wizard/Wizard.module.css'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { FlexContainer } from '@layout/FlexContainer'
import { ResponsiveSection } from '@layout/ResponsiveSection'
import styles from './WizardSummary.module.css'

export type Obligation = Readonly<{
  obligation: ReadonlyArray<SummaryRecord>
}>

export type SummaryRecord = Readonly<{
  label: string
  value: OptionalType<string>
}>

export type NestedSection = Readonly<{
  title: string
  data: ReadonlyArray<Obligation>
}>

const isSummaryRecord = (data: SummaryRecord | NestedSection): data is SummaryRecord => {
  return Object.hasOwn(data, 'label')
}

export type WizardSummaryProps = Readonly<{
  title: string
  vehicleId?: VehicleId
  data: ReadonlyArray<SummaryRecord | NestedSection>
  onConfirm: Handler<void>
  onBack: Handler<void>
}> &
  CommonControlProps

export const WizardSummary: React.FC<WizardSummaryProps> = ({
  title,
  vehicleId,
  data,
  onConfirm,
  onBack,
  disabledControls
}) => {
  const { t } = useTranslation()

  return (
    <div className={wizardStyles.wizardContainer}>
      {vehicleId && (
        <ResponsiveSection title={t('global.forms.selectedCar.title')} showTitleAlways>
          <SelectedVehicle vehicleId={vehicleId} disabledControls={disabledControls} />
        </ResponsiveSection>
      )}
      <ResponsiveSection title={title} showTitleAlways>
        <FlexContainer vertical className={styles.summaryTable}>
          {data.map((record, index) =>
            isSummaryRecord(record) ? (
              <FlexContainer key={`summary-data-${index}`} justifyContentSpaceBetween fixedDirection>
                <span>{record.label}</span>
                <span className={styles.valueText}>{record.value}</span>
              </FlexContainer>
            ) : (
              <FlexContainer key={`summary-data-${index}`} vertical>
                {record?.data?.map((obl, i) => (
                  <Accordion
                    className={styles.obligationAccordion}
                    key={`summary-data-obligation-${i}`}
                    disableGutters={true}
                    sx={{
                      '&:before': {
                        display: 'none' // https://stackoverflow.com/questions/63488140/how-can-i-remove-line-above-the-accordion-of-material-ui
                      }
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 className={styles.subtitle}>{t('global.forms.obligations.text', { index: i + 1 })}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                      {obl.obligation.map((record, i) => (
                        <FlexContainer key={`summary-obligation-data-${i}`} justifyContentSpaceBetween fixedDirection>
                          <span>{record.label}</span>
                          <span className={styles.valueText}>{record.value}</span>
                        </FlexContainer>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </FlexContainer>
            )
          )}
        </FlexContainer>
      </ResponsiveSection>
      <ActionButtonsContainer fixedDirection justifyContentSpaceBetween noTopGap>
        <Button variant="contained" color="white" onClick={() => onBack()}>
          {t('global.buttons.back')}
        </Button>
        <Button variant="contained" color="darkBlue" onClick={() => onConfirm()}>
          {t('global.buttons.apply')}
        </Button>
      </ActionButtonsContainer>
    </div>
  )
}
