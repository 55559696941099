import * as React from 'react'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { InstallmentRequest, postInstallmentRequest } from '@api/endpoints/forms'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'

type Props = UseMutationProps

export const useMutationRequestInstallment = ({
  onSuccess
}: Props): UseMutationResult<void, unknown, InstallmentRequest> => {
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()
  const mutation = useMutation({
    mutationFn: postInstallmentRequest
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
