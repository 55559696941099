import * as React from 'react'
import { Control, Controller, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OptionalType } from '@digital-magic/ts-common-utils'
import { formatEuroInEstonian } from '@model/language'
import { Price, VehicleId } from '@api/endpoints'
import { CarLoanPeriodEnumSchema, LoanTotalAmount, PaymentDayEnumSchema } from '@api/endpoints/forms'
import { InputAdornment } from '@mui/material'
import Slider from '@mui/material/Slider'
import formStyles from '@assets/css/forms.module.css'
import { ControlledFormProps } from '@controls/types'
import { MenuItemEntry, buildEnumOptions, renderMenuItems } from '@controls/utils'
import { FormTextField } from '@controls/Form'
import { FormSlider } from '@controls/Form/FormSlider/FormSlider'
import { FlexContainer } from '@layout/FlexContainer'
import { FormWithObligationsValues } from '@forms/types'
import { paymentDayLabel } from '@forms/utils'
import { ObligationFormInputs } from '@forms/ObligationFormInputs'
import { useInstallmentStore } from '../useInstallmentStore'
import { maxLoanMonth, minLoanMonth, monthsAnyToLoanPeriod, periodInMonths } from './utils'
import { InstallmentFormValues } from './InstallmentApplication'
import styles from './InstallmentApplication.module.css'

type SelectedVehicleInfo = Readonly<{
  vehicleId: VehicleId
  price?: Price
}>

export type InstallmentApplicationFormProps = Readonly<{
  vehicleId: VehicleId
  totalAmountMin: LoanTotalAmount
  totalAmountMax: LoanTotalAmount
  setTotalAmountMax: React.Dispatch<React.SetStateAction<LoanTotalAmount>>
}> &
  ControlledFormProps<InstallmentFormValues>

export const InstallmentApplicationFormInputs: React.FC<InstallmentApplicationFormProps> = ({
  vehicleId,
  totalAmountMin,
  totalAmountMax,
  setTotalAmountMax,
  control,
  disabledControls
}) => {
  const { t } = useTranslation()

  const [selectedVehicle, setSelectedVehicle] = React.useState<OptionalType<SelectedVehicleInfo>>(
    vehicleId ? { vehicleId } : undefined
  )

  const installmentData = useInstallmentStore((s) => s.installmentData)

  const loanPeriodOptions: ReadonlyArray<MenuItemEntry> = React.useMemo(
    () => buildEnumOptions(CarLoanPeriodEnumSchema, (v) => periodInMonths(v).toString()),
    []
  )

  const paymentDayOptions: ReadonlyArray<MenuItemEntry> = React.useMemo(
    () => buildEnumOptions(PaymentDayEnumSchema, (v) => paymentDayLabel(v).toString()),
    []
  )

  const { field: carLoanPeriodField } = useController({ name: 'carLoanPeriod', control })
  const { field: vehicleIdField } = useController({ name: 'carprofCarId', control })

  React.useEffect(() => {
    // TODO: Check if vehicle will be removed - will form show error?
    if (selectedVehicle?.vehicleId) {
      vehicleIdField.onChange(selectedVehicle.vehicleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle?.vehicleId])

  React.useEffect(() => {
    if (installmentData && installmentData.carprofCarId) {
      setSelectedVehicle({ vehicleId: installmentData.carprofCarId })
    }
  }, [installmentData])

  React.useEffect(() => {
    if (selectedVehicle?.price) {
      setTotalAmountMax(Math.round(selectedVehicle.price / periodInMonths(carLoanPeriodField.value)))
    }
  }, [setTotalAmountMax, selectedVehicle?.price, carLoanPeriodField.value])

  return (
    <FlexContainer justifyContentSpaceBetween>
      <FlexContainer vertical halfWidth>
        <FormTextField
          control={control}
          name="personalIdCode"
          className={formStyles.required}
          label={t('pages.installment.application.fields.personalIdCode')}
          disabled={disabledControls}
        />
        <FormTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('app.currency')}</InputAdornment>
          }}
          control={control}
          name="monthlyIncome"
          type="number"
          className={formStyles.required}
          label={t('pages.installment.application.fields.monthlyIncome')}
          disabled={disabledControls}
        />
        <div>
          <FormTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('app.currency')}</InputAdornment>
            }}
            control={control}
            name="totalAmount"
            type="number"
            className={formStyles.required}
            label={t('pages.installment.application.fields.monthlyPayments')}
            // inputProps={{ min: monthlyPaymentMin, max: monthlyPaymentMax }}
            disabled={disabledControls}
          />
          <FormSlider
            control={control}
            name="totalAmount"
            max={totalAmountMax}
            min={totalAmountMin}
            //valueLabelDisplay="on"
            size="medium"
            disabled={disabledControls}
            // DON'T USE MARKS ATTRIBUTE HERE, IT WILL BREAK WHOLE PAGE (PAGE WILL HANG)
          />
          <FlexContainer
            fixedDirection
            justifyContentSpaceBetween
            responsiveGap
            className={styles.labelMaxMinContainer}
          >
            <span>{formatEuroInEstonian(totalAmountMin)}</span>
            <span>{formatEuroInEstonian(totalAmountMax)}</span>
          </FlexContainer>
        </div>
        <FormTextField
          select
          control={control}
          name="paymentDay"
          type="text"
          label={t('pages.installment.application.fields.paymentDay')}
          disabled={disabledControls}
        >
          {renderMenuItems(paymentDayOptions)}
        </FormTextField>
        <div>
          <FormTextField
            select
            control={control}
            name="carLoanPeriod"
            type="text"
            className={formStyles.required}
            label={t('pages.installment.application.fields.carLoanPeriod')}
            disabled={disabledControls}
          >
            {renderMenuItems(loanPeriodOptions)}
          </FormTextField>
          <Controller
            control={control}
            name="carLoanPeriod"
            render={(props) => (
              <Slider
                size="medium"
                {...props.field}
                onChange={(_, value) => props.field.onChange(monthsAnyToLoanPeriod(value))}
                value={periodInMonths(props.field.value)}
                max={maxLoanMonth}
                min={minLoanMonth}
                step={12}
                //valueLabelDisplay="on"
                marks
                disabled={disabledControls}
              />
            )}
          />
          <FlexContainer
            fixedDirection
            justifyContentSpaceBetween
            responsiveGap
            className={styles.labelMaxMinContainer}
          >
            <span>{`${minLoanMonth} ${t('pages.installment.application.fields.months')}`}</span>
            <span>{`${maxLoanMonth} ${t('pages.installment.application.fields.months')}`}</span>
          </FlexContainer>
        </div>
      </FlexContainer>
      <FlexContainer halfWidth>
        <ObligationFormInputs
          // TODO: A hack to make it work, because react-hook-forms doesn't support polymorphic types (maybe we must not use form but use form.control in form components): https://github.com/orgs/react-hook-form/discussions/9016
          control={control as unknown as Control<FormWithObligationsValues>}
          disabledControls={disabledControls}
        />
      </FlexContainer>
    </FlexContainer>
  )
}
