import { CarLoanPeriodEnum } from '@api/endpoints/forms/types'

const LoanMonthsValue = [12, 24, 36, 48, 60, 72, 84, 96] as const
type LoanMonthsValue = (typeof LoanMonthsValue)[number]

const isLoanMonthsValue = (value: number): value is LoanMonthsValue => {
  return LoanMonthsValue.includes(value as LoanMonthsValue)
}

export const periodInMonths = (value: CarLoanPeriodEnum): number => {
  switch (value) {
    case 'MONTHS_12':
      return 12
    case 'MONTHS_24':
      return 24
    case 'MONTHS_36':
      return 36
    case 'MONTHS_48':
      return 48
    case 'MONTHS_60':
      return 60
    case 'MONTHS_72':
      return 72
    case 'MONTHS_84':
      return 84
    case 'MONTHS_96':
      return 96
  }
}

export const monthsToLoanPeriod = (value: LoanMonthsValue): CarLoanPeriodEnum => {
  switch (value) {
    case 12:
      return 'MONTHS_12'
    case 24:
      return 'MONTHS_24'
    case 36:
      return 'MONTHS_36'
    case 48:
      return 'MONTHS_48'
    case 60:
      return 'MONTHS_60'
    case 72:
      return 'MONTHS_72'
    case 84:
      return 'MONTHS_84'
    case 96:
      return 'MONTHS_96'
  }
}

export const minLoanMonth = LoanMonthsValue[0]
export const maxLoanMonth = LoanMonthsValue[LoanMonthsValue.length - 1]

export const minLoanPeriod = monthsToLoanPeriod(minLoanMonth)
export const maxLoanPeriod = monthsToLoanPeriod(maxLoanMonth)

export const monthsAnyToLoanPeriod = (value: number | Array<number> | undefined | null): CarLoanPeriodEnum =>
  typeof value === 'number' && isLoanMonthsValue(value) ? monthsToLoanPeriod(value) : maxLoanPeriod
