import * as React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  FormTypeSchema,
  InstallmentRequestSchema,
  LoanTotalAmount,
  LoanTotalAmountSchema
} from '@api/endpoints/forms/types'
import { Button } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { CommonControlProps } from '@controls/types'
import { useZodForm } from '@controls/Form'
import { ResponsiveSection } from '@layout/ResponsiveSection'
import { ApplicationStep } from '../types'
import { useInstallmentStore } from '../useInstallmentStore'
import { InstallmentSelectedVehicle } from '../InstallmentSelectedVehicle'
import { InstallmentContactsView } from '../InstallmentContactsView'
import { InstallmentApplicationFormInputs } from './InstallmentApplicationFormInputs'
import styles from './InstallmentApplication.module.css'
import { maxLoanPeriod } from '@pages/Installment/InstallmentWizard/InstallmentApplication/utils'
import wizardStyles from '@controls/Wizard/Wizard.module.css'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import * as z from 'zod'
import { FormWithObligationsSchema } from '@forms/types'
import { useFormTrackedWrapper } from '@hooks/Tracking'

const InstallmentFormSchema = InstallmentRequestSchema.omit({ obligations: true }).merge(FormWithObligationsSchema)
export type InstallmentFormValues = z.infer<typeof InstallmentFormSchema>

export const InstallmentApplication: React.FC<CommonControlProps> = ({ disabledControls }) => {
  const { t } = useTranslation()

  const [totalAmountMax, setTotalAmountMax] = React.useState<LoanTotalAmount>(LoanTotalAmountSchema.maxValue ?? 100000)

  const vehicleId = useInstallmentStore((s) => s.carprofVehicleId)
  const setNextStep = useInstallmentStore((s) => s.setNextStep)
  const setPreviousStep = useInstallmentStore((s) => s.setPreviousStep)
  const installmentData = useInstallmentStore((s) => s.installmentData)
  const setInstallmentData = useInstallmentStore((s) => s.setInstallmentData)
  const activeStep = useInstallmentStore((s) => s.activeStep)

  const totalAmountMin = LoanTotalAmountSchema.minValue ?? 0

  const form = useZodForm<InstallmentFormValues>({
    schema: InstallmentFormSchema.omit({ totalAmount: true }).extend({
      totalAmount: LoanTotalAmountSchema.min(totalAmountMin).max(totalAmountMax)
    }),
    defaultValues: {
      ...installmentData,
      totalAmount: installmentData?.totalAmount ?? 0,
      carLoanPeriod: installmentData?.carLoanPeriod ?? maxLoanPeriod
    }
  })

  const handleBack = (): void => {
    setPreviousStep(ApplicationStep)
  }

  const handleFormSubmit = (data: InstallmentFormValues): void => {
    setInstallmentData(data)
    setNextStep(ApplicationStep)
  }

  const formSubmitHandler = useFormTrackedWrapper(
    { formName: FormTypeSchema.enum.CAR_LOAN, step: activeStep },
    form,
    handleFormSubmit
  )

  if (!vehicleId) {
    // Don't show component content because this situation is only possible when page navigates away to catalog
    return null
  }

  return (
    <div className={clsx(wizardStyles.wizardContainer, styles.installmentApplicationContainer)}>
      <ResponsiveSection title={t('global.forms.selectedCar.title')}>
        <InstallmentSelectedVehicle vehicleId={vehicleId} disabledControls={disabledControls} />
      </ResponsiveSection>
      <ResponsiveSection title={t('pages.installment.contactInfo.title')}>
        <InstallmentContactsView />
      </ResponsiveSection>

      <form onSubmit={formSubmitHandler.handleSubmit}>
        <ResponsiveSection title={t('pages.installment.application.formTitle')}>
          <InstallmentApplicationFormInputs
            vehicleId={vehicleId}
            totalAmountMin={totalAmountMin}
            totalAmountMax={totalAmountMax}
            setTotalAmountMax={setTotalAmountMax}
            control={form.control}
            disabledControls={disabledControls}
          />
        </ResponsiveSection>
        <input type="submit" className={formStyles.inputHidden} />
      </form>
      <ActionButtonsContainer fixedDirection justifyContentSpaceBetween noTopGap>
        <Button variant="contained" disabled={disabledControls} color="white" onClick={handleBack}>
          {t('global.buttons.back')}
        </Button>
        <Button
          variant="contained"
          disabled={disabledControls}
          color="darkBlue"
          onClick={formSubmitHandler.handleSubmit}
        >
          {t('global.buttons.continue')}
        </Button>
      </ActionButtonsContainer>
    </div>
  )
}
