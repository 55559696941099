import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { OptionalType, parseOptionalIntNanSafe, toOptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { FinancialObligationTypeEnum } from '@api/endpoints/forms/types'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@hooks/useTheme'
import { useMutationRequestInstallment } from '@hooks/ReactQuery/forms/useMutationRequestInstallment'
import { ContainerIdProps } from '@controls/types'
import { Wizard } from '@controls/Wizard'
import { WizardConfirmationSection } from '@controls/Wizard/WizardConfirmationSection'
import { ApplicationStep, CustomerDataStep, StepsList, SummaryStep } from './types'
import { useInstallmentStore } from './useInstallmentStore'
import { installmentStepTranslation } from './utils'
import { InstallmentApplication } from './InstallmentApplication'
import { InstallmentSummary } from './InstallmentSummary'
import { InstallmentCustomer } from './InstallmentCustomer'
import { sendConversionEvent } from '@api/tracking'

export const InstallmentWizard: React.FC<ContainerIdProps> = ({ containerId }) => {
  const { t } = useTranslation()
  const [initialStep, setInitialStep] = React.useState<boolean>(true)

  const { currentTheme } = useTheme()
  const isDownMd = useMediaQuery(currentTheme.breakpoints.down('md'))
  const isDownSm = useMediaQuery(currentTheme.breakpoints.down('sm'))

  const scrollPoint = isDownSm ? 130 : isDownMd ? 270 : 290

  const [searchParams, setSearchParams] = useSearchParams()
  const vehicleId: OptionalType<VehicleId> = parseOptionalIntNanSafe(toOptionalType(searchParams.get('vehicleId')))

  const activeStep = useInstallmentStore((s) => s.activeStep)
  const installmentData = useInstallmentStore((s) => s.installmentData)
  const setVehicleId = useInstallmentStore((s) => s.setCarprofVehicleId)
  const setFirstStep = useInstallmentStore((s) => s.setFirstStep)
  const clear = useInstallmentStore((s) => s.clear)

  const createInstallmentRequest = useMutationRequestInstallment({
    onSuccess: () => {
      sendConversionEvent()
      clear()
      setSearchParams(new URLSearchParams())
    }
  })

  const stepTranslation = installmentStepTranslation(t)

  const handleConfirm = (): void => {
    // TODO: Data must be submitted on "back" as well?
    if (installmentData) {
      createInstallmentRequest.mutate({
        ...installmentData,
        obligations: installmentData.obligations.map((item) => ({
          ...item,
          // TODO: Why we have to cast here?
          amountOrLimit: item.amountOrLimit as number,
          financer: item.financer,
          monthlyPayment: item.monthlyPayment as number,
          obligationType: item.obligationType as FinancialObligationTypeEnum
        }))
      })
    }
  }

  React.useEffect(() => {
    if (vehicleId) {
      setVehicleId(vehicleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId])

  const disabledControls = createInstallmentRequest.isPending

  const renderStep = (): React.ReactElement => {
    switch (activeStep) {
      case CustomerDataStep:
        return <InstallmentCustomer disabledControls={disabledControls} />
      case ApplicationStep:
        return <InstallmentApplication />
      case SummaryStep:
        return <InstallmentSummary onConfirm={handleConfirm} />
    }
  }

  React.useEffect(() => {
    // We use initialStep-state to prevent scrolling to the stepper on the very first page load
    if (initialStep) {
      setInitialStep(false)
    } else if (containerId && !initialStep) {
      document.getElementById(containerId)?.scrollTo(0, scrollPoint)
      console.log(installmentData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  React.useEffect(() => {
    if (activeStep !== CustomerDataStep && !vehicleId) {
      setFirstStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return createInstallmentRequest.isSuccess ? (
    <WizardConfirmationSection topic="installment" onReset={createInstallmentRequest.reset} />
  ) : (
    <Wizard
      steps={StepsList}
      activeStep={activeStep}
      buildStepTitle={stepTranslation}
      buildStepCompleted={(step) => step < activeStep}
      renderStep={renderStep}
    />
  )
}
