import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { Page, PageContent } from '@layout/Page'
import { PageContainerWithHead } from '@layout/Page'
import { InstallmentWizard } from './InstallmentWizard'

export const InstallmentPage: React.FC = () => {
  const { t } = useTranslation()
  const { routes } = useLanguage()
  const pageId = React.useId()

  const breadCrumbs = React.useMemo(
    () => [{ to: routes.Index, title: t('pages.main.title') }, { title: t('pages.installment.title') }],
    [routes, t]
  )

  return (
    <Page id={pageId}>
      <PageContainerWithHead showCar title={t('pages.installment.title')} breadCrumbs={breadCrumbs}>
        <PageContent subtitle={t('pages.installment.text')}>
          <InstallmentWizard containerId={pageId} />
        </PageContent>
      </PageContainerWithHead>
    </Page>
  )
}
