import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { NestedSection, SummaryRecord, WizardSummary, WizardSummaryProps } from '@forms/WizardSummary'
import { formatEuroInEstonian } from '@model/language'
import { useInstallmentStore } from '../useInstallmentStore'
import { SummaryStep } from '../types'
import { periodInMonths } from '../InstallmentApplication/utils'
import { hasValue } from '@digital-magic/ts-common-utils'

type Props = Pick<WizardSummaryProps, 'onConfirm'>

export const InstallmentSummary: React.FC<Props> = ({ onConfirm }) => {
  const { t } = useTranslation()
  const { obligationTypeTranslation } = useEnumTranslation()

  const clientData = useInstallmentStore((s) => s.clientData)
  const installmentData = useInstallmentStore((s) => s.installmentData)
  const setPreviousStep = useInstallmentStore((s) => s.setPreviousStep)

  const handleBack = (): void => {
    setPreviousStep(SummaryStep)
  }

  const data: ReadonlyArray<SummaryRecord | NestedSection> = React.useMemo(
    () =>
      installmentData
        ? [
            {
              label: t('global.forms.customer.fields.name'),
              value: clientData?.name
            },
            {
              label: t('global.forms.customer.fields.email'),
              value: clientData?.email
            },
            {
              label: t('global.forms.customer.fields.phone'),
              value: clientData?.phoneNumber
            },
            {
              label: t('pages.installment.application.fields.monthlyIncome'),
              value: formatEuroInEstonian(installmentData.monthlyIncome)
            },
            /*
            {
              label: t('pages.installment.application.fields.totalAmount'),
              value: formatEuroInEstonian(installmentData.totalAmount)
            },
            */
            {
              label: t('pages.installment.application.fields.monthlyPayments'),
              value: formatEuroInEstonian(installmentData.totalAmount)
            },
            {
              label: t('pages.installment.application.fields.carLoanPeriod'),
              value: periodInMonths(installmentData.carLoanPeriod).toString()
            },
            {
              title: t('global.forms.obligations.title'),
              data:
                installmentData?.obligations.map((obligation) => ({
                  obligation: [
                    {
                      label: t('global.forms.obligations.amountOrLimit'),
                      value: obligation.amountOrLimit ? formatEuroInEstonian(obligation.amountOrLimit) : undefined
                    },
                    {
                      label: t('global.forms.obligations.financer'),
                      value: obligation.financer
                    },
                    {
                      label: t('global.forms.obligations.monthlyPayment'),
                      value: obligation.monthlyPayment ? formatEuroInEstonian(obligation.monthlyPayment) : undefined
                    },
                    {
                      label: t('global.forms.obligations.obligationType.title'),
                      value: obligation.obligationType
                        ? obligationTypeTranslation(obligation.obligationType)
                        : undefined
                    }
                  ].filter((v) => hasValue(v.value)) as ReadonlyArray<SummaryRecord>
                })) ?? []
            }
          ].filter((v) => (Object.hasOwn(v, 'value') ? hasValue(v.value) : hasValue(v.data) && v.data.length > 0))
        : [],
    [clientData, installmentData, obligationTypeTranslation, t]
  )

  return (
    <WizardSummary
      title={t('pages.installment.confirmation.title')}
      vehicleId={installmentData?.carprofCarId}
      data={data}
      onConfirm={onConfirm}
      onBack={handleBack}
    />
  )
}
