import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { create } from 'zustand'
import { Handler, OptionalType } from '@digital-magic/ts-common-utils'
import { VehicleId } from '@api/endpoints'
import { ClientPersonalData } from '@api/endpoints/forms'
import { InstallmentStep, StepsList } from './types'
import { InstallmentFormValues } from './InstallmentApplication'

export type State = Readonly<{
  activeStep: InstallmentStep
  privacyConsent: boolean
  clientData: OptionalType<ClientPersonalData>
  installmentData: OptionalType<InstallmentFormValues>
  carprofVehicleId: OptionalType<VehicleId>
}>

export type Actions = Readonly<{
  setFirstStep: Handler<void>
  setNextStep: Handler<InstallmentStep>
  setPrivacyConsent: Handler<boolean>
  setPreviousStep: Handler<InstallmentStep>
  setClientData: Handler<ClientPersonalData>
  setInstallmentData: Handler<InstallmentFormValues>
  setCarprofVehicleId: Handler<VehicleId | undefined>
  clear: Handler<void>
}>

const calcNextStep = (step: InstallmentStep): InstallmentStep =>
  step === StepsList[StepsList.length - 1] ? step : StepsList[StepsList.indexOf(step) + 1]

const calcPrevStep = (step: InstallmentStep): InstallmentStep =>
  step === StepsList[0] ? step : StepsList[StepsList.indexOf(step) - 1]

const initialState: State = {
  activeStep: StepsList[0],
  privacyConsent: false,
  clientData: undefined,
  installmentData: undefined,
  carprofVehicleId: undefined
}

export const useInstallmentStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setFirstStep: () => set(() => ({ activeStep: StepsList[0] })),
        setNextStep: (step) => set((state) => (state.activeStep === step ? { activeStep: calcNextStep(step) } : state)),
        setPrivacyConsent: (consent) => set(() => ({ privacyConsent: consent })),
        setPreviousStep: (step) =>
          set((state) => (state.activeStep === step ? { activeStep: calcPrevStep(step) } : state)),
        setClientData: (data) => set(() => ({ clientData: data })),
        setInstallmentData: (data) => set(() => ({ installmentData: data })),
        setCarprofVehicleId: (data) => set(() => ({ carprofVehicleId: data })),
        clear: () => set(() => initialState)
      }),
      {
        name: 'installmentForm',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
