import * as React from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FinancialObligationTypeEnumSchema } from '@api/endpoints/forms/types'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { IconButton, InputAdornment } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { ControlledFormProps } from '@controls/types'
import { MenuItemEntry, buildEnumOptions, renderMenuItems } from '@controls/utils'
import { FormTextField } from '@controls/Form'
import SvgMinusButtonIcon from '@assets/icons/minusIcon.svg?react'
import SvgPlusButtonIcon from '@assets/icons/plusIcon.svg?react'
import { FlexContainer } from '@layout/FlexContainer'
import { FormWithObligationsValues } from './types'

const maxObligations = 3

export const ObligationFormInputs = ({
  control,
  disabledControls
}: ControlledFormProps<FormWithObligationsValues>): React.ReactElement => {
  const { t } = useTranslation()
  const { obligationTypeTranslation } = useEnumTranslation()

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'obligations'
  })

  const obligationTypeOptions: ReadonlyArray<MenuItemEntry> = React.useMemo(
    () => buildEnumOptions(FinancialObligationTypeEnumSchema, obligationTypeTranslation),
    [obligationTypeTranslation]
  )

  return (
    <FlexContainer vertical>
      <FlexContainer fixedDirection responsiveGap alignItemsCenter>
        <h3 className={formStyles.formSubtitle}>{t('global.forms.obligations.title')}</h3>
        <IconButton
          className={formStyles.plusIconBtn}
          onClick={() => {
            if (fields.length < maxObligations) {
              // https://react-hook-form.com/api/usefieldarray/#:~:text=When%20you%20append%2C%20prepend%2C%20insert%20and%20update%20the%20field%20array%2C%20the%20obj%20can%27t%20be%20empty%20object%20rather%20need%20to%20supply%20all%20your%20input%27s%20defaultValues.
              append({
                amountOrLimit: '',
                financer: '',
                monthlyPayment: '',
                obligationType: ''
              })
            }
          }}
          disabled={disabledControls}
          disableRipple={true}
        >
          <SvgPlusButtonIcon />
        </IconButton>
      </FlexContainer>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <FlexContainer fixedDirection responsiveGap alignItemsCenter>
            <h3 className={formStyles.formSubtitle}>{t('global.forms.obligations.text', { index: index + 1 })}</h3>
            <IconButton
              className={formStyles.plusIconBtn}
              onClick={() => remove(index)}
              disabled={disabledControls}
              disableRipple={true}
            >
              <SvgMinusButtonIcon />
            </IconButton>
          </FlexContainer>

          <FormTextField
            control={control}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('app.currency')}</InputAdornment>
            }}
            type="number"
            name={`obligations.${index}.amountOrLimit`}
            label={t('global.forms.obligations.amountOrLimit')}
            disabled={disabledControls}
          />
          <FormTextField
            control={control}
            type="text"
            name={`obligations.${index}.financer`}
            label={t('global.forms.obligations.financer')}
            disabled={disabledControls}
          />
          <FormTextField
            control={control}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('app.currency')}</InputAdornment>
            }}
            type="number"
            name={`obligations.${index}.monthlyPayment`}
            label={t('global.forms.obligations.monthlyPayment')}
            disabled={disabledControls}
          />
          <FormTextField
            select
            control={control}
            type="text"
            name={`obligations.${index}.obligationType`}
            label={t('global.forms.obligations.obligationType.title')}
            disabled={disabledControls}
          >
            {renderMenuItems(obligationTypeOptions)}
          </FormTextField>
        </React.Fragment>
      ))}
    </FlexContainer>
  )
}
