import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '@hooks/useLanguage'
import { SelectedVehicle, SelectedVehicleProps } from '@forms/SelectedVehicle'
import { VehicleSelectionMode } from '@pages/types'
import { urlCatalogWithSelectionMode } from '@pages/utils'
import { useInstallmentStore } from './useInstallmentStore'

export const InstallmentSelectedVehicle: React.FC<Omit<SelectedVehicleProps, 'onChooseAnotherClick'>> = ({
  vehicleId,
  disabledControls
}) => {
  const navigate = useNavigate()
  const setVehicleId = useInstallmentStore((s) => s.setCarprofVehicleId)
  const { language } = useLanguage()

  const handleChooseAnotherClick = (): void => {
    setVehicleId(undefined)
    navigate(urlCatalogWithSelectionMode(VehicleSelectionMode.Installment, language))
  }

  return (
    <SelectedVehicle
      vehicleId={vehicleId}
      onChooseAnotherClick={handleChooseAnotherClick}
      disabledControls={disabledControls}
    />
  )
}
