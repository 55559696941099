import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactFormView } from '@forms/ContactFormView'
import { useInstallmentStore } from './useInstallmentStore'

export const InstallmentContactsView: React.FC = () => {
  const { t } = useTranslation()

  const clientData = useInstallmentStore((s) => s.clientData)

  if (!clientData) {
    // TODO: Handle it with return to previous step
    // eslint-disable-next-line no-console
    console.error('Client data must be set!')
    return null
  }

  return <ContactFormView title={t('global.forms.contactInfo.contacts')} data={clientData} />
}
